import React from 'react'

const lessonBanner = (props) => (
    <section id="banner" className="style2" style={{height:'600px'}}>
        <div className="inner">
            <header className="major">
                <h1>課程介紹</h1>
            </header>
            <div className="content">
                <p>三健客3musclers健身俱樂部，有多元的台北健身課程、一對一私人教練課程、團體課程、研習課程等，部份課程也提供體驗課程，協助您可以更瞭解自己的體能狀況，並找到最適合您的健身計劃，與您一起達成最佳的理想體態。
                </p>
            </div>
        </div>
    </section>
)

export default lessonBanner
