import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import LessonBanner from '../components/lessonBanner'

import banciao1 from '../assets/images/IMG_7883.jpg'
import banciao2 from '../assets/images/page02.jpg'
import banciao3 from '../assets/images/IMG_7885.jpg'

const Landing = props => (
  <Layout>
    <Helmet
	 	title="台北健身課程介紹｜三健客3musclers健身俱樂部"
      meta={[
        {
          name: 'description',
          content: '三健客3musclers健身俱樂部，有多元的台北健身課程、一對一私人教練課程、團體課程、研習課程等，部份課程也提供體驗課程，且有30分鐘可免費諮詢及活動度檢測，協助您可以更瞭解自己的體能狀況，並找到最適合您的健身計劃，與您一起達成最佳的理想體態。',
        },
		  {
			  name:'keywords',
			  content:'三健客,健身房,內湖健身房,板橋健身房,永和健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程'
		  },
        {
          property: 'og:title',
          content: '三健客 3musclers｜專業私人教練課',
        },
        {
          property: 'og:site_name',
          content: '三健客 3musclers｜專業私人教練課',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: 'https://www.3musclers.com/lesson',
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '960',
        },
        {
          property: 'og:image',
          content:
            'https://user-images.githubusercontent.com/53250201/84196417-bbde8d00-aad2-11ea-9627-8b4687bc76bc.JPG',
        },
        {
          property: 'og:description',
          content:
            '如同三劍客中主角聚集了一群志同道合的夥伴一起面對強權，也是由一群健身訓練者聚集而成，在健身這個自我修煉的路途中互相陪伴。',
        },
      ]}
    >
    </Helmet>

    <LessonBanner />

    <div id="main">
      {/* <section id="one" style={{backgroundColor:'#d2aa0d'}}>
        <div className="inner">
          <header className="major">
            <h2>場館介紹</h2>
          </header>
          <p>
            目前三健客擁有三間分店，分別為內湖店、板橋店、永和店
            <br />
          </p>
        </div>
      </section> */}
      <section id="two" className="spotlights">
        <section style={{}}>
          <Link to="/coach" className="image">
            <img src={banciao2} alt="3musclers" style={{ height: '100%' }} />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>教練課程</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/coach" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section style={{}}>
          <Link to="/groupLesson" className="image">
            <img src={banciao3} alt="3musclers" style={{ height: '100%' }} />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>團體課程</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/groupLesson" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section style={{}}>
          <Link to="/404" className="image">
            <img
              src={banciao1}
              alt="3musclers"
              style={{ objectFit: 'cover' }}
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>研習課程</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/404" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
